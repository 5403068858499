import {TFuncKey, Namespace, KeyPrefix} from 'i18next';
import {Trans as TransBase, TransProps as BaseTransProps} from 'react-i18next';
import {DefaultNamespace} from 'react-i18next/TransWithoutContext';

export type TransProps<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
> = BaseTransProps<K, N, TKPrefix, E>;

export function Trans<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(props: TransProps<K, N, TKPrefix, E>) {
  return (
    <TransBase
      {...props}
      components={{
        black: <span className="font-black" />,
        bold: <span className="font-bold" />,
        thin: <span className="font-thin" />,
        medium: <span className="font-medium" />,
        light: <span className="font-light" />,
      }}
    />
  );
}
