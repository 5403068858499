/* eslint-disable jsx-a11y/heading-has-content */
export interface TitleProps {
  level?: 'h1' | 'h2' | 'h3';
  className?: string;
  children?: React.ReactNode;
  id?: string;
}

export function TitleBase({level, ...props}: TitleProps) {
  switch (level) {
    case 'h2':
      return <h2 {...props} />;
    case 'h3':
      return <h3 {...props} />;
  }

  return <h1 {...props} />;
}
